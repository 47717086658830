export const urls = {
  pharmacies: '/api/pharmacies',
  contracts: '/api/contracts',
  prescribers: 'api/prescribers',
  prescriberLocations: 'api/prescriber-locations',
  patients: 'api/patients',
  insurances: 'api/insurances',
  sigs: 'api/sigs',
  extendedDrugs: 'api/extended-drugs',
  fdbDrugs: 'api/fdb/drugs',
  facilities: 'api/facilities',
  pricePlans: 'api/price-plans',
  allergies: '/api/allergies',
  medicalConditions: '/api/medical-conditions',
  prescriptions: '/api/prescriptions',
  deliveries: '/api/deliveries',
  intake: '/api/prescriptions/intake',
  screen: '/api/fdb/screen',
  users: '/api/users',
  userTypes: '/api/user-types',
  permissions: '/api/permissions',
  tags: '/api/tags',
  erxMessages: '/api/surescript/messages',
  billPrescription: (prescriptionId: string) => `/api/prescriptions/${prescriptionId}/bill`,
  billStatus: (prescriptionId: string) => `/api/prescriptions/${prescriptionId}/bill-status`,
  inventory: '/api/inventory',
  recycleBin: '/api/recycle-bin/items',
  labelTemplates: '/api/label-templates',
  reports: '/api/reports',
  reportsDownload: '/api/reports/download',
  registerFirebaseClient: '/api/notifications/register',
  notifications: '/api/notifications',
  systemNotifications: '/api/system-notifications',
  notificationsMarkAllAsRead: '/api/notifications/mark-all-as-read',
  notificationStatus: (notificationId: string) => `/api/notifications/${notificationId}/status`,
  printers: '/api/printers',
  claims: '/api/claims',
  eligibilityChecks: '/api/claims/eligibility-checks',
  configurations: (pharmacyId: string) => `/api/pharmacies/${pharmacyId}/configurations`,
  payerSheets: '/api/payer-sheets',
  payerSheetsDefault: '/api/payer-sheets/default',
  switchVendors: '/api/switch-vendors',
  userPreferences: '/api/user-preferences',
  prescriptionStatusOptions: '/api/prescriptions/status-options',
  statisticsReport: '/api/reports/statistics',
  textMessages: '/api/text-messages'
};

export const localUrls = {
  print: 'http://localhost:3090/print'
};
